import React from "react";
import { Link } from "gatsby";

import { Box, FlexRow, H4, H6, PrismicImage, Text } from "src/components";

const Tag = ({ children }) => (
  <Box
    bg="bg.reverse"
    css={`
      padding: 8px;
    `}
  >
    <H6 children={children} color="text.reverse" />
  </Box>
);

const Subtag = ({ children }) => (
  <Box
    bg="brand.primary"
    css={`
      padding: 8px;
    `}
  >
    <H6 children={children} color="text.reverse" />
  </Box>
);

const ConcertItem = ({ venue, date, status, heading, image, to }) => (
  <Box>
    <Link to={to}>
      <Box position="relative">
        <PrismicImage
          image={image}
          style={{ maxHeight: "512px", minHeight: "256px" }}
          hideAttribution={true}
        />
        <FlexRow
          css={`
            position: absolute;
            top: 0;
            left: 16px;
          `}
        >
          <Tag children="Concert" />
          <Subtag children={status} />
        </FlexRow>
      </Box>
    </Link>
    {heading && (
      <Text as="h1" mt={3}>
        <Link children={heading} to={to} />
      </Text>
    )}
    {(date || venue) && (
      <Text mt={1} fontSize={[0, 1]} color="text.alt">
        {date && date}
        {date && venue && ` · `}
        {venue && venue}
      </Text>
    )}
  </Box>
);

export default ConcertItem;
