import React from "react";
import { cover } from "polished";
import { Link } from "gatsby";

import { Box, FlexRow, H4, H6, PrismicImage, Text } from "src/components";

const Tag = ({ children }) => (
  <Box
    bg="bg.reverse"
    css={`
      position: absolute;
      top: 0;
      left: 16px;
      padding: 8px;
    `}
  >
    <H6 children={children} color="text.reverse" />
  </Box>
);

const Avatar = ({ image }) => (
  <div
    css={`
      width: 30px;
    `}
  >
    <div
      css={`
        position: relative;
        padding-bottom: 100%;
        border-radius: 50%;
        overflow: hidden;
      `}
    >
      <PrismicImage
        image={image}
        style={{ ...cover(), width: "100%", height: "100%" }}
        hideAttribution={true}
      />
    </div>
  </div>
);

const ArticleItem = ({
  authorImage,
  author,
  caption,
  date,
  heading,
  image,
  to
}) => (
  <Box>
    <Link to={to}>
      <Box position="relative">
        <PrismicImage
          image={image}
          style={{ maxHeight: "512px", minHeight: "256px" }}
        />
        <Tag children="Article" />
      </Box>
    </Link>
    {heading && (
      <Text as="h1" mt={3}>
        <Link children={heading} to={to} />
      </Text>
    )}
    {caption && <Text children={caption} mt={1} color="text.body" />}
    {(author || date) && (
      <FlexRow justifyContent="flex-start" alignItems="center" mt={1}>
        {authorImage && authorImage.url && <Avatar image={authorImage} />}
        {(author || date) && (
          <Box flex={1} ml={authorImage && authorImage.url && 2}>
            <Text fontSize={[0, 1]} color="text.alt">
              {author && author}
              {author && date && ` · `}
              {date && date}
            </Text>
          </Box>
        )}
      </FlexRow>
    )}
  </Box>
);

export default ArticleItem;
