import React from "react";
import { cover } from "polished";
import { Link } from "gatsby";

import {
  Box,
  FlexCol,
  FlexGrid,
  FlexRow,
  H4,
  H6,
  PrismicImage,
  Text
} from "src/components";

const Tag = ({ children }) => (
  <Box
    bg="bg.reverse"
    css={`
      position: absolute;
      bottom: 0;
      left: 16px;
      padding: 8px;
    `}
  >
    <H6 children={children} color="text.reverse" />
  </Box>
);

const Avatar = ({ image }) => (
  <div
    css={`
      width: 30px;
    `}
  >
    <div
      css={`
        position: relative;
        padding-bottom: 100%;
        border-radius: 50%;
        overflow: hidden;
      `}
    >
      <PrismicImage
        image={image}
        style={{ ...cover(), width: "100%", height: "100%" }}
      />
    </div>
  </div>
);

const AnnouncementItem = ({
  author,
  authorImage,
  date,
  heading,
  image,
  to
}) => (
  <Box
    pt={[5, null, 7]}
    css={`
      border-top: 1px solid ${props => props.theme.colors.bg.alt};
    `}
  >
    <FlexGrid gutterX={[5]}>
      <FlexCol width={1 / 2}>
        <Link to={to}>
          <FlexCol position="relative" flex={1} minHeight="160px">
            <PrismicImage
              image={image}
              style={{ ...cover() }}
              hideAttribution={true}
            />
            <Tag children="Announcement" />
          </FlexCol>
        </Link>
      </FlexCol>
      <FlexCol width={1 / 2}>
        {heading && (
          <Text as="h1">
            <Link children={heading} to={to} />
          </Text>
        )}
        {(author || date) && (
          <Box mt="auto">
            <FlexRow justifyContent="flex-start" alignItems="center" mt={3}>
              {authorImage && authorImage.url && <Avatar image={authorImage} />}
              {(author || date) && (
                <Box flex={1} ml={authorImage && authorImage.url && 2}>
                  <Text fontSize={[0, 1]} color="text.alt">
                    {author && author}
                    {author && date && ` · `}
                    {date && date}
                  </Text>
                </Box>
              )}
            </FlexRow>
          </Box>
        )}
      </FlexCol>
    </FlexGrid>
  </Box>
);

export default AnnouncementItem;
