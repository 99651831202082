import React, { useState, useEffect } from "react";
import { RichText } from "prismic-reactjs";
import { StaticQuery, graphql } from "gatsby";
import moment from "moment";

import { FlexCol, SEO, SectionWrapper, Button } from "src/components";

import Categories from "./_components/_categories.js";
import Items from "./_components/_items.js";

function News({ setInvert, setInvertLogo, preview }) {
  return (
    <StaticQuery
      query={graphql`
        {
          news: prismicNews {
            dataString
          }
          authors: allPrismicStaffMember {
            edges {
              node {
                prismicId
                dataString
              }
            }
          }
          concerts: allPrismicConcer {
            edges {
              node {
                uid
                dataString
              }
            }
          }
          artists: allPrismicArtist {
            edges {
              node {
                uid
                dataString
              }
            }
          }
          announcements: allPrismicAnnouncement {
            edges {
              node {
                uid
                dataString
              }
            }
          }
          press: allPrismicPress {
            edges {
              node {
                dataString
              }
            }
          }
          posts: allPrismicPost {
            edges {
              node {
                uid
                dataString
              }
            }
          }
        }
      `}
      render={data => {
        let news = JSON.parse(data.news.dataString);
        if (preview) {
          news = preview;
        }
        let concerts = [];
        data.concerts.edges.forEach(concert => {
          let concertParsed = JSON.parse(concert.node.dataString);
          let concertFinal = { uid: concert.node.uid, ...concertParsed };
          data.artists.edges.forEach(artist => {
            if (artist.node.uid === concertParsed.performer.uid) {
              concertFinal.performerData = JSON.parse(artist.node.dataString);
            }
            if (artist.node.uid === concertParsed.opener.uid) {
              concertFinal.openerData = JSON.parse(artist.node.dataString);
            }
          });
          concerts.push(concertFinal);
        });
        let announcements = [];
        data.announcements.edges.forEach(announcement => {
          let announcementParsed = {
            ...JSON.parse(announcement.node.dataString),
            uid: announcement.node.uid
          };
          data.authors.edges.forEach(author => {
            if (announcementParsed.author) {
              if (author.node.prismicId === announcementParsed.author.id) {
                announcementParsed.authorData = JSON.parse(
                  author.node.dataString
                );
              }
            }
          });
          announcements.push(announcementParsed);
        });
        let press = [];
        data.press.edges.forEach(pressItem => {
          let pressParsed = JSON.parse(pressItem.node.dataString);
          press.push(pressParsed);
        });
        let posts = [];
        data.posts.edges.forEach(post => {
          let postParsed = {
            ...JSON.parse(post.node.dataString),
            uid: post.node.uid
          };
          data.authors.edges.forEach(author => {
            if (postParsed.author) {
              if (author.node.prismicId === postParsed.author.id) {
                postParsed.authorData = JSON.parse(author.node.dataString);
              }
            }
          });
          posts.push(postParsed);
        });
        return (
          <NewsInner
            news={news}
            concerts={concerts}
            posts={posts}
            press={press}
            announcements={announcements}
            setInvert={setInvert}
            setInvertLogo={setInvertLogo}
          />
        );
      }}
    />
  );
}

function NewsInner({
  news,
  concerts,
  posts,
  press,
  announcements,
  setInvert,
  setInvertLogo
}) {
  const [activeFilter, setActiveFilter] = useState("all");
  const [items, setItems] = useState([]);
  const [length, setLength] = useState(20);
  const [possibleLength, setPossibleLength] = useState(0);

  useEffect(() => {
    let newItems = [];
    // concerts.forEach(concert => {
    //   newItems.push({
    //     link: "/concert/" + concert.uid,
    //     title:
    //       concert.performerData &&
    //       concert.performerData.artist_name &&
    //       RichText.asText(concert.performerData.artist_name),
    //     subtext: concert.venue,
    //     status: concert.ticket_state,
    //     date: concert.date && moment(concert.date).format("MMM D, YYYY"),
    //     datestamp: moment(concert.date).unix(),
    //     image: concert.performerData && concert.performerData.image,
    //     type: "Concert"
    //   });
    // });
    announcements.forEach(announcement => {
      newItems.push({
        link: "/announcement/" + announcement.uid,
        title: announcement.title && RichText.asText(announcement.title),
        image: announcement.image,
        date:
          announcement.date && moment(announcement.date).format("MMM D, YYYY"),
        datestamp: moment(announcement.date).unix(),
        authorImage: announcement.authorData && announcement.authorData.picture,
        author:
          announcement.authorData &&
          announcement.authorData.name &&
          RichText.asText(announcement.authorData.name),
        type: "announcement"
      });
    });
    press.forEach(press => {
      newItems.push({
        href: press.link && press.link.url,
        title: press.title && RichText.asText(press.title) + " ↗",
        date: press.date && moment(press.date).format("MMM D, YYYY"),
        datestamp: moment(press.date).unix(),
        subtext: press.news_outlet,
        image: press.image,
        type: "press"
      });
    });
    posts.forEach(article => {
      newItems.push({
        link: "/post/" + article.uid,
        title: article.title && RichText.asText(article.title),
        date: article.date && moment(article.date).format("MMM D, YYYY"),
        datestamp: moment(article.date).unix(),
        image: article.image,
        authorImage: article.authorData && article.authorData.picture,
        author:
          article.authorData &&
          article.authorData.name &&
          RichText.asText(article.authorData.name),
        type: "article"
      });
    });
    setPossibleLength(newItems.length);
    newItems = newItems.sort((a, b) => {
      return b.datestamp - a.datestamp;
    });
    setItems(newItems);
    if (window.location.search.indexOf("?filter=") !== -1) {
      setActiveFilter(window.location.search.replace("?filter=", ""));
    }
  }, []);

  return (
    <>
      <SEO
        image={
          news.opengraph_image &&
          news.opengraph_image.url &&
          news.opengraph_image.url
        }
        imageAlt={
          news.opengraph_image &&
          news.opengraph_image.alt &&
          news.opengraph_image.alt
        }
        title={news.opengraph_title && RichText.asText(news.opengraph_title)}
        description={
          news.opengraph_description &&
          RichText.asText(news.opengraph_description)
        }
      />
      <Categories
        title={news.intro && RichText.asText(news.intro)}
        intro={news.intro && RichText.asText(news.intro)}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        setInvert={setInvert}
        setInvertLogo={setInvertLogo}
      />
      <SectionWrapper>
        <Items
          activeFilter={activeFilter}
          concerts={concerts}
          items={items}
          length={length}
        />
        {possibleLength - 1 > length && (
          <FlexCol mb={6} alignItems="center">
            <Button
              children={"Load more"}
              onClick={() => {
                setLength(length + 10);
              }}
            />
          </FlexCol>
        )}
      </SectionWrapper>
    </>
  );
}

export default News;
