import React from "react";
import Masonry from "react-masonry-component";

import { Box } from "src/components";

import ArticleItem from "./_articleItem";
import ConcertItem from "./_concertItem";
import PressItem from "./_pressItem";
import AnnouncementItem from "./_announcementItem";

function Items({ activeFilter, items, length }) {
  return (
    <Box pb={[7, 9]}>
      <Box m={[-3, null, -5]}>
        <Masonry
          options={{
            transitionDuration: 0
          }}
        >
          {items
            .slice(0, length)
            .map(
              (
                {
                  image,
                  author,
                  authorImage,
                  title,
                  subtext,
                  status,
                  date,
                  link,
                  href,
                  type
                },
                index
              ) => {
                if (type === activeFilter || activeFilter === "all") {
                  return (
                    <>
                      {type === "article" && (
                        <Box width={[1, 1 / 2]} p={[3, null, 5]}>
                          <ArticleItem
                            heading={title}
                            caption={subtext}
                            author={author}
                            authorImage={authorImage}
                            date={date}
                            image={image}
                            to={link}
                          />
                        </Box>
                      )}
                      {type === "concert" && (
                        <Box width={[1, 1 / 2]} p={[3, null, 5]}>
                          <ConcertItem
                            heading={title}
                            date={date}
                            venue={subtext}
                            image={image}
                            status={status}
                            to={link}
                          />
                        </Box>
                      )}
                      {type === "press" && (
                        <Box width={[1, 1 / 2]} p={[3, null, 5]}>
                          <PressItem
                            heading={title}
                            date={date}
                            source={subtext}
                            href={href}
                          />
                        </Box>
                      )}
                      {type === "announcement" && (
                        <Box width={[1, 1 / 2]} p={[3, null, 5]}>
                          <AnnouncementItem
                            heading={title}
                            date={date}
                            author={author}
                            authorImage={authorImage}
                            image={image}
                            to={link}
                          />
                        </Box>
                      )}
                    </>
                  );
                } else {
                  return null;
                }
              }
            )}
        </Masonry>
      </Box>
    </Box>
  );
}

export default Items;
