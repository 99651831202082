import React from "react";

import { Box, FlexRow, H3, H6, Text } from "src/components";

const Tag = ({ children }) => (
  <Box
    bg="bg.reverse"
    css={`
      padding: 8px;
    `}
  >
    <H6 children={children} color="text.reverse" />
  </Box>
);

const PressItem = ({ href, date, heading, source }) => (
  <Box
    pt={[5, null, 7]}
    css={`
      border-top: 1px solid ${props => props.theme.colors.bg.alt};
    `}
  >
    {heading && (
      <Text as="h1" fontSize={[3, 4]}>
        <a
          children={heading}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        />
      </Text>
    )}
    <FlexRow justifyContent="flex-start" alignItems="flex-start" mt={3}>
      <Tag children="Press" />
      {(source || date) && (
        <Box flex={1} mt="6px" ml={2}>
          <Text fontSize={[0, 1]} color="text.alt">
            {source && source}
            {source && date && ` · `}
            {date && date}
          </Text>
        </Box>
      )}
    </FlexRow>
  </Box>
);

export default PressItem;
