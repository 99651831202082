import React from "react";

import {
  Box,
  PageHeaderV2,
  FlexGrid,
  Radio,
  SectionWrapper
} from "src/components";

function Categories({
  title,
  activeFilter,
  setActiveFilter,
  setInvert,
  setInvertLogo
}) {
  return (
    <>
      <PageHeaderV2
        heading={title}
        color="brand.altPrimary"
        invert={true}
        setInvertLogo={setInvertLogo}
        setInvert={setInvert}
      />
      <SectionWrapper py={[7, 8]} bg="bg.default">
        <FlexGrid gutterY={[1]} gutterX={[7]}>
          <Box width={1 / 2}>
            <Radio
              children={"All news"}
              checked={"all" === activeFilter}
              for="activeFilter"
              onClick={() => {
                setActiveFilter("all");
              }}
            />
          </Box>
          {/* <Box width={1 / 2}>
            <Radio
              children={"Concerts"}
              checked={"Concert" === activeFilter}
              for="activeFilter"
              onClick={() => {
                setActiveFilter("Concert");
              }}
            />
          </Box> */}
          <Box width={1 / 2}>
            <Radio
              children={"Articles"}
              checked={"article" === activeFilter}
              for="activeFilter"
              onClick={() => {
                setActiveFilter("article");
              }}
            />
          </Box>
          <Box width={1 / 2}>
            <Radio
              children={"Announcements"}
              checked={"announcement" === activeFilter}
              for="activeFilter"
              onClick={() => {
                setActiveFilter("announcement");
              }}
            />
          </Box>
          <Box width={1 / 2}>
            <Radio
              children={"Press"}
              checked={"press" === activeFilter}
              for="activeFilter"
              onClick={() => {
                setActiveFilter("press");
              }}
            />
          </Box>
        </FlexGrid>
      </SectionWrapper>
    </>
  );
}

export default Categories;
